import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Gastroenteritis in Children";

// Download link - http://www.fbrennangastro.com.au/downloads/gesagastroinchildren.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "Gastro", "Diarrhoea", "Bali belly", "Delhi belly"]}
			type="article"
			description="Gastroenteritis (traveller's diarrhoea) when it affects your child. How to recognise it and how to manage it"
		/>
		<H1>{title}</H1>
		<Par>
			Gastroenteritis, often called 'gastro', is an infection of the gut that causes diarrhoea and sometimes vomiting,
			fever and abdominal pain. It is a common illness that is infectious and easily spread. It tends to be a more
			serious problem in babies and small children.
		</Par>
		<Par>
			Gastroenteritis is usually caused by viruses, the most common is Rotavirus. Sometimes other types of germs
			including bacteria and parasites may also cause it.
		</Par>
		<Par>It is commonly spread by coming into contact with another person with the illness.</Par>
		<Par>
			It is highly infectious so your child should not attend school, day care or kindergarten while they are sick or
			still have diarrhoea. Good hand washing with soap and water after handling any ill person, changing nappies and
			before food preparation and eating is important in helping to stop the spread of infection.
		</Par>
	</Blog>
);
